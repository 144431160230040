@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .bg-primary {
        background: #79ABAE;
    }
    .bg-complementary {
        background: #F4AB5D;
    }
    .text-white {
        color: white;
    }
    .text-primary {
        color: #79ABAE;
    }
    .text-complementary {
        color: #F4AB5D;
    }
}

body {
    font-family: 'Poppins', sans-serif;
}
